<template>
    <profile-layout>
        <template #header>
            <div
                class="arrowWrap"
                @click="toProfile"
            >
                <arrow />
            </div>
            Change email
        </template>
        <template #content>
            <div class="input-field">
                <form-input
                    v-model.trim="email"
                    legend="Enter new email"
                    prepend-icon="EmailOutlineIcon"
                    :loading="loadEmail"
                    :field="email"
                    :errors="errorText || null"
                    type="text"
                    name="email"
                />
            </div>
            <div class="input-field">
                <form-input
                    v-model.trim="emailConfirmation"
                    legend="Confirm your email"
                    prepend-icon="EmailOutlineIcon"
                    type="text"
                    name="email"
                />
            </div>
            <password-modal
                :name="'password-test-email'"
                :title="'Change email'"
            />
        </template>
        <template #footer>
            <custom-button
                default
                class="button btn-base_colored"
                :type="'button'"
                width="100%"
                @on-btn-click="validationData"
            >
                Submit
            </custom-button>
        </template>
    </profile-layout>
</template>

<script>
import {
    ACTION_GET_CURRENT_USER
} from '@/store/modules/auth/action-types'
import { createNamespacedHelpers } from 'vuex'

import { changeEmail, checkEmail } from '@/services/profileService';
import ProfileLayout from '@/layouts/ProfileLayout'
import PasswordModal from '@/components/modals/PasswordModal';
import Arrow from '@/icons/Arrow'

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('auth')

export default {
    name: 'ChangeEmail',
    components: { ProfileLayout, Arrow, PasswordModal },
    data() {
        return {
            email: '',
            emailConfirmation: '',
            errorText: null,
            loadEmail: false
        }
    },
    watch: {
        email() {
            this.loadEmail = true
            this.errorText = ''
            this.$nextTick(() => {
                if (!this.email) { return }
                checkEmail(this.email).catch((err) => {
                    this.errorText = err.response?.data?.errors?.email[0]
                }).finally(() => {
                    this.loadEmail = false
                })
            })
        }
    },
    activated() {
        document.querySelector('input')?.focus()
        this.$bus.$on('confirm', this.submit)
    },
    deactivated() {
        this.$bus.$off('confirm', this.submit)
    },
    methods: {
        ...mapAdminActions([
            ACTION_GET_CURRENT_USER
        ]),
        toProfile() {
            this.$router.push({ name: 'user-profile' })
        },
        reset() {
            this.email = ''
            this.emailConfirmation = ''
        },
        validationData() {
            this.errorText = ''
            if (!this.email) {
                this.errorText = "Email can't be empty"
                this.$bus.$emit('showSnackBar', "Email can't be empty", 'error')
                return
            }
            if (this.email !== this.emailConfirmation) {
                this.errorText = 'Emails not matched'
                this.$bus.$emit('showSnackBar', 'Emails not matched', 'error')
                return
            }
            this.$bus.$emit('openModal', 'password-test-email')
        },
        submit(name) {
            if (name !== 'password-test-email') { return }
            changeEmail(this.email).then(async () => {
                await this[ACTION_GET_CURRENT_USER]()
                this.$bus.$emit('showSnackBar', 'Your email was changed', 'success')
                this.reset()
                this.toProfile()
            }).catch((err) => {
                this.errorText = err.response?.data?.errors?.email[0]
                this.$bus.$emit('showSnackBar', err.response?.data?.errors?.email[0] || 'Server error', 'error')
            })
        }
    }
}
</script>

<style scoped>
.input-field {
    margin-bottom: 35px;
}
</style>
